import React from 'react';

const Navbar = () => (
  <nav className="bg-black shadow-lg p-4 flex justify-between items-center">
    <div className="flex items-center">
      <img src="/logolandscape.png" alt="Skoolie Films Logo" className="h-12 md:h-20" />
    </div>
    <div className="flex space-x-4">
      <a href="#home" className="text-xl font-poppins font-semibold text-white hover:text-yellow-400">Home</a>
      <a href="#ourwork" className="text-xl font-poppins font-semibold text-white hover:text-yellow-400">Work</a>
      <a href="#contact" className="text-xl font-poppins font-semibold text-white hover:text-yellow-400">Contact</a>
    </div>
  </nav>
);

export default Navbar;
