import React from 'react';

const OurWork = () => {
  const videoUrls = [
    "https://www.youtube.com/embed/Wg_N10pgHRg",
    "https://www.youtube.com/embed/K3bDL85Cyqc",
    "https://www.youtube.com/embed/9gd02jnT7_8",
    "https://www.youtube.com/embed/2oLmSEb67pY",
    "https://www.youtube.com/embed/Qkdoo6WaT_g",
    "https://www.youtube.com/embed/7k_wYWha4Nk",
    "https://www.youtube.com/embed/uDT_-fd4yWQ",
    "https://www.youtube.com/embed/k8ADiCOtAxA",
    "https://www.youtube.com/embed/BSEvZqIUzOo" // New video added here
  ];

  return (
    <div id="ourwork" className="bg-gray-900 text-white p-8 min-h-screen">
      <h2 className="text-4xl font-bold text-center text-yellow-400 mb-8">Our Work</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {videoUrls.map((url, index) => (
          <div key={index} className="relative w-full h-0 pb-[56.25%]">
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src={url}
              title={`YouTube video ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurWork;
