import React from 'react';

const ThreeBoxComponent = () => {
  const boxContent = [
    {
      header: "Full-Service Marketing Strategy",
      description: "Skoolie Films is your premier creative production partner specializing in brand strategy and high-impact video production. With extensive industry experience, we deliver a seamless, stress-free process that transforms your vision into cinematic content. We don’t just create videos—we craft powerful brand narratives that captivate audiences and drive results."
    },
    {
      header: "Solar-Powered Production Bus",
      description: "Skoolie Films brings a fully equipped mobile production straight to your location, eliminating logistical challenges and maximizing efficiency. Powered by solar technology and a high-capacity battery bank, we deliver professional-grade cameras, grip, and electric equipment directly to set—ensuring a seamless, self-sufficient production without compromise."
    },
    {
      header: "Over A Decade of Production Excellence",
      description: "For over ten years, Skoolie Films has delivered top-tier productions in Atlanta. With deep industry roots and an insider’s knowledge of the city, our team navigates the local film landscape with precision—ensuring every production runs seamlessly and successfully."
    }
  ];

  return (
    <div
      className="bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: "url('/busbg.jpg')" }}
    >
      {/* Button Section */}
      <div className="flex items-center py-20">
        <div className="container mx-auto px-4 text-center">
          <a
            href="/Skoolie%20Gear%20List.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-yellow-400 text-black text-5xl font-bold py-8 px-16 rounded-lg shadow-lg hover:bg-yellow-500 hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-1"
          >
            2 TON G&E PACKAGE
          </a>
        </div>
      </div>

      {/* Three Boxes Section */}
      <div className="flex items-center py-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-center gap-6">
            {boxContent.map((box, index) => (
              <div key={index} className="bg-black p-6 flex flex-col justify-between w-full md:w-1/3 rounded-lg shadow-lg">
                <h2 className="font-poppins text-yellow-400 text-xl md:text-2xl lg:text-3xl font-bold mb-4">
                  {box.header}
                </h2>
                <p className="font-poppins text-white text-sm md:text-base lg:text-lg">
                  {box.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeBoxComponent;
