import React from 'react';

const Footer = () => (
  <footer id="contact" className="bg-gray-800 text-white p-4 text-center">
    <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-4">
      <a href="#home" className="hover:underline font-body">Home</a>
      <a href="#about" className="hover:underline font-body">About</a>
      <a href="#contact" className="hover:underline font-body">Contact</a>
    </div>
    {/* Socials Section */}
    <div className="flex justify-center space-x-4 mt-4">
      <a 
        href="https://www.facebook.com/share/167pMrJKAS/" 
        target="_blank" 
        rel="noopener noreferrer"
        className="hover:opacity-80 transition duration-300"
      >
        <img src="/facebook.webp" alt="Facebook" className="w-6 h-6" />
      </a>
      <a 
        href="https://www.instagram.com/skooliefilms?igsh=ajNtdTRnMmJzdzJh" 
        target="_blank" 
        rel="noopener noreferrer"
        className="hover:opacity-80 transition duration-300"
      >
        <img src="/instagram.webp" alt="Instagram" className="w-6 h-6" />
      </a>
      <a 
        href="https://www.linkedin.com/company/skooliefilms/" 
        target="_blank" 
        rel="noopener noreferrer"
        className="hover:opacity-80 transition duration-300"
      >
        <img src="/linkedin.png" alt="LinkedIn" className="w-6 h-6" />
      </a>
    </div>
    <p className="mt-4 font-body text-white">
      &copy; 2024 Skoolie Films. All rights reserved
    </p>
  </footer>
);

export default Footer;
