import React from 'react';

const TrustedClientsBanner = () => {
  return (
    <div className="bg-yellow-500 text-gray-900 py-10 px-6 text-center">
      <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-poppins font-semibold my-4">
        Skoolie Films.
      </h1>
      <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl my-4 font-poppins">
        The marketing agency driven by video. We deliver high-impact content that gets results—from commercials and corporate films to social media campaigns.
      </p>
      <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl my-4 font-poppins">
        With our one-of-a-kind, solar-powered mobile studio and 2-ton G&E package, we bring top-tier production straight to you, streamlining logistics and maximizing efficiency.
      </p>
      <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl my-4 font-poppins">
        Ready to elevate your brand? Contact Skoolie Films for a fast quote and let’s bring your vision to life.
      </p>
    </div>
  );
};

export default TrustedClientsBanner;
